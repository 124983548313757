import * as React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {getImage} from "../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import ogimage from "../images/ogimages/events.png";

const EventSingle = ({data}) => {
    const {contentfulEventPost} = data;
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - ' + contentfulEventPost.title || 'Agoric',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - ' + contentfulEventPost.title || 'Agoric',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - {contentfulEventPost.title}</title>
            </Helmet>
            <main className="blog blogpost">
                <section>
                    <div className="container">
                        <div className="row blogpost--title">
                            <div className="col-lg-12">
                                <h2>{contentfulEventPost.title}</h2>
                            </div>
                        </div>
                        <div className="row blogpost--image">
                            <div className="col-lg-12">
                                <div className="decorate-container">
                                    <div className="decorate">
                                        <span className="decorate__item"/>
                                        <span className="decorate__item"/>
                                    </div>
                                </div>
                                <img src={getImage(contentfulEventPost.image).url}
                                     alt={getImage(contentfulEventPost.image).title}
                                     className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                />
                            </div>
                        </div>
                        <div className="row blogpost--content pb-5 mb-5">
                            <div className="col-lg-9">
                                <div className="post-head">
                                    <div className="date">
                                        <p>{contentfulEventPost.date}</p>
                                    </div>
                                </div>
                                <div className="post-content">
                                    {renderRichText(contentfulEventPost.body)}
                                    { contentfulEventPost.jointEventUrl &&
                                        <a href={contentfulEventPost.jointEventUrl} className={'btn'} target={'_blank'} rel={'noreferrer'}>
                                            {contentfulEventPost.replaceJoinText ?? 'Join event'}
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default EventSingle;

export const query = graphql`
    query EventBySlug(
        $slug: String!
    ) {
        contentfulEventPost(slug: { eq: $slug }) {
            id
            title
            date(formatString: "MMMM DD, YYYY")
            excerpt
            jointEventUrl
            body {
                raw
            }
            slug
            image {
                title
                url
            }
            replaceJoinText
        }
    }
`
